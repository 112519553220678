import {Injectable} from '@angular/core';
import {SkwApiService} from 'skw-ui-bootstrap';
import {Observable} from 'rxjs';
import {SearchParameter} from './search-parameter';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private api: SkwApiService) {
  }

  search(param: SearchParameter): Observable<any> {
    return this.api.post<void>('/carBodySearch/', param);
  }

  scroll(scrollId: string): Observable<any> {
    return this.api.post<void>('/carBodySearch/scroll', scrollId);
  }

  targetSearch(param: SearchParameter): Observable<any> {
    return this.api.post<void>('/carBodySearch/target/', param);
  }

}
