import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DisplayAttrSettingComponent} from './display-attr-setting/display-attr-setting.component';
import {
  SkwAlertModule,
  SkwButtonModule,
  SkwCardModule, SkwDialogModule,
  SkwEditableModule,
  SkwFormModule,
  SkwHintModule,
  SkwInputModule,
  SkwListModule,
  SkwLoadingModule,
  SkwTableModule
} from 'skw-ui-components';
import {SkwLanguageModule} from 'skw-ui-translation';
import {SettingsComponent} from './settings.component';
import {SkwMasterDetailLayoutModule, SkwPageLayoutModule} from 'skw-ui-layout';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DisplayAttrOverviewComponent} from './display-attr-overview/display-attr-overview.component';
import {TargetingSettingComponent} from './targeting-setting/targeting-setting.component';
import {LayerSettingComponent} from './layer-setting/layer-setting.component';
import {AdvancedSettingsComponent} from './advanced-settings/advanced-settings.component';
import {SkwTabsModule} from '../../skw-tabs/skw-tabs.module';
import {SequencingSettingComponent} from './sequencing-setting/sequencing-setting.component';
import {MatSliderModule} from '@angular/material/slider';
import {ColorAdminSettingComponent} from "./color-admin-setting/color-admin-setting.component";
import {ProportionSettingComponent} from "./proportion-setting/proportion-setting.component";
import {
  ChooseAttributeDialogComponent
} from "./proportion-setting/choose-attribute-dialog/choose-attribute-dialog.component";
import {
  EmergencyTransferalDialogComponent
} from "../dashboard/emergency-transferal-dialog/emergency-transferal-dialog.component";
import {MfwItemModule} from "skw-ui-modules";
import {TargetHistoryDialogComponent} from "./proportion-setting/target-history-dialog/target-history-dialog.component";
import {CarBodySearchModule} from "../carbodysearch/carbodysearch.module";
import {MfwComponentsModule} from "../../mfw-components/mfw-components.module";

@NgModule({
  declarations: [
    DisplayAttrSettingComponent,
    SettingsComponent,
    DisplayAttrOverviewComponent,
    TargetingSettingComponent,
    LayerSettingComponent,
    AdvancedSettingsComponent,
    SequencingSettingComponent,
    ProportionSettingComponent,
    ChooseAttributeDialogComponent,
    TargetHistoryDialogComponent,
    LayerSettingComponent,
    ColorAdminSettingComponent
  ],
  exports: [
    DisplayAttrSettingComponent,
    SettingsComponent,
    DisplayAttrOverviewComponent,
    TargetingSettingComponent,
    SequencingSettingComponent,
    ProportionSettingComponent,
    ChooseAttributeDialogComponent,
    TargetHistoryDialogComponent,
    LayerSettingComponent,
    ColorAdminSettingComponent
  ],
  imports: [
    CommonModule,
    SkwInputModule,
    SkwFormModule,
    SkwLanguageModule,
    SkwPageLayoutModule,
    DragDropModule,
    SkwLoadingModule,
    SkwButtonModule,
    SkwHintModule,
    SkwAlertModule,
    SkwListModule,
    SkwEditableModule,
    SkwMasterDetailLayoutModule,
    SkwTableModule,
    SkwCardModule,
    SkwTabsModule,
    MatSliderModule,
    SkwDialogModule,
    MfwItemModule,
    CarBodySearchModule,
    MfwComponentsModule
  ],
  entryComponents: [ChooseAttributeDialogComponent, TargetHistoryDialogComponent]
})
export class SettingsModule {
}
