import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
  deepCopy,
  indicate,
  SkwDialogService,
  SkwErrorHandlerService,
  SkwSelectModel,
  SkwSnackBarService
} from 'skw-ui-components';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {SequencingSettings, SequencingSettingsService} from '../sequencing-settings.service';
import {ProportionCountsService} from "./proportion-counts.service";
import {SkwLanguageService, SkwTranslatableValue, TranslationPipe} from "skw-ui-translation";
import {MdsEntityAttribute} from "../../rules/rule-definitions-model";
import {RulesService} from "../../rules/rules.service";
import {ChooseAttributeDialogComponent} from "./choose-attribute-dialog/choose-attribute-dialog.component";
import { timer } from 'rxjs';
import {map} from "rxjs/operators";
import {TargetHistoryDialogComponent} from "./target-history-dialog/target-history-dialog.component";

export class ProportionSettings {
  attributeValue: string;
  editing: boolean;
  proportions: LaneProportion[];

  originalProportions: LaneProportion[];

  constructor(attributeValue: string, proportions: LaneProportion[]) {
    this.attributeValue = attributeValue;
    this.proportions = proportions;
  }

  startEditing() {
    this.originalProportions = deepCopy([...this.proportions]);
    this.editing = true;
  }

  discard() {
    this.proportions = deepCopy([...this.originalProportions]);
    this.editing = false;
  }

  apply() {
    this.editing = false;
  }
}

export class LaneProportion {
  target: string
  proportion: number;
  translation?: string;
}

@Component({
  selector: 'proportion-setting',
  templateUrl: './proportion-setting.component.html',
  styleUrls: ['./proportion-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProportionSettingComponent implements OnInit {
  sequencingSettings: { [key: string]: SequencingSettings[] };

  $loading = new BehaviorSubject(true);
  dirty = false;
  syncedSettings = false;

  proportionAttributeId: number;

  proportionSettings: ProportionSettings[] = [];

  proportionCounts: any;
  currentTotal = {};
  currentTotalPlanned = {};
  totalPlannedInvalid: boolean;
  editing: boolean;
  proportionControlResetEnabled: boolean;

  addAttributeValue: string = "";
  selectedAttribute: MdsEntityAttribute;
  mdsEntityAttributes: SkwSelectModel<MdsEntityAttribute>[];
  attributeValues: SkwSelectModel<string>[] = [];
  translatePipe: TranslationPipe;

  layers = [{value: '280', viewValue: '280'},
    {value: '380', viewValue: '380'},
    {value: '480', viewValue: '480'}];

  constructor(public service: SequencingSettingsService,
              private cdr: ChangeDetectorRef,
              private dialogService: SkwDialogService,
              private errorHandler: SkwErrorHandlerService,
              private proportionService: ProportionCountsService,
              private snackBar: SkwSnackBarService,
              private rulesService: RulesService,
              private languageService: SkwLanguageService) {
      this.translatePipe = this.languageService.createTranslationPipeInstance(cdr);
  }

  ngOnInit() {
    this.reloadTargetingSettings();
    this.reloadCounts();
  }

  reloadCounts() {
      this.proportionService.getProportionCounts()
          .subscribe(counts => {
              this.proportionCounts = counts.counts;

              Object.keys(this.proportionCounts).forEach(k => {
                  this.currentTotal[k] = Object.values(counts.counts[k])
                      .reduce((sum, setting) => sum + setting.currentCount, 0);

                  this.currentTotal[k] = Object.values(counts.counts[k])
                      .reduce((sum, setting) => sum + setting.currentCount, 0)
              });

              this.cdr.markForCheck();
          });
  }

  saveSequencingSettings() {
      Object.keys(this.sequencingSettings)
          .filter(s => s.startsWith(`proportionControl__`))
          .sort()
          .forEach(s => {
              delete this.sequencingSettings[s];
          });

      this.proportionSettings.forEach(ps => {
          ps.proportions.forEach(p => {
              const settingsName = `proportionControl__` + ps.attributeValue + '*' + p.target;
              console.log(settingsName);
              if (this.sequencingSettings[settingsName]) {
                  this.sequencingSettings[settingsName][0].value = p.proportion;
              } else {

                  this.sequencingSettings[settingsName] = [{
                      settingKey: settingsName,
                      value: p.proportion
                  } as SequencingSettings];
              }
          })
      });

      this.service.saveAll(Object.values(this.sequencingSettings).reduce((a, b) => a.concat(b), []))
          .pipe(indicate(this.$loading))
          .subscribe(() => this.snackBar.showInfo('messages.success-saved'), error => this.errorHandler.handleHttpError(error));
      this.dirty = false;
  }

  loadAttributeDataFromRuleService() {
    this.rulesService.getRuleDefinitionsModel().subscribe(value => {
        // get all mds fields from this definition model
        this.mdsEntityAttributes = value.mdsEntityAttributes.map(mdsEntityAttribute => {
            return {
                value: mdsEntityAttribute,
                viewValue: {
                    key: 'pages.mds.entityTypesPrefix.' + mdsEntityAttribute.entityTypeName,
                    args: {name: mdsEntityAttribute.attributeName}
                } as SkwTranslatableValue
            } as SkwSelectModel<MdsEntityAttribute>;
        });

        // find the selected attribute for the skw-select-input
        const selectModel = this.mdsEntityAttributes.find(x => x.value.id == this.proportionAttributeId);
        this.selectedAttribute = selectModel ? selectModel.value : undefined;
        this.cdr.markForCheck();


        if (!this.selectedAttribute) {
            return;
        }
        this.rulesService.getCarBodyAttributeValuesFromId(this.selectedAttribute.entityTypeName, this.selectedAttribute.id)
            .subscribe(r => {
                if (!r) {
                    this.attributeValues = [];
                    return;
                }
                this.attributeValues = r.map(a => {
                    return {
                        value: a.value.toString(),
                        viewValue: a.viewValue
                    };
                });
                this.cdr.markForCheck();
            });
    });
  }

  reloadTargetingSettings() {
    this.service.loadAll()
      .pipe(indicate(this.$loading))
      .subscribe(value => {
        this.sequencingSettings = {};
        Object.keys(value).forEach(k => this.sequencingSettings[k] = value[k].map(v => {
          return {...v};
        })); // deep copy object to make it editable
        if (this.sequencingSettings[this.service.SETTINGS_SYNCED] !== undefined
          && this.sequencingSettings[this.service.SETTINGS_SYNCED][0].value === 'true') {
          this.syncedSettings = true;
        }
        this.proportionControlResetEnabled = this.sequencingSettings[this.service.PROPORTION_CONTROL_RESET_ENABLED][0].value === 'true';

        const settings: { [key: string]: LaneProportion[] } = {};
        Object.keys(value)
            .filter(s => s.startsWith(`proportionControl__`))
            .sort()
            .forEach(s => {
                const key = s.replace(`proportionControl__`, '').split('*');
                const proportions = settings[key[0]] || [];
                proportions.push({target: key[1], proportion: +this.sequencingSettings[s][0].value})
                settings[key[0]] = proportions;
            });

        this.proportionSettings = [];
        Object.entries(settings).forEach(([target, proportions]) => {
            this.proportionSettings.push(new ProportionSettings(target, proportions));
            proportions.sort((a, b) =>  {
                return this.translate(a)  > this.translate(b) ? 1 : -1;
            });
        });
        Object.entries(settings).forEach(([target, proportions]) => {
            this.currentTotalPlanned[target] = proportions
                  .reduce((sum, setting) => sum + setting.proportion, 0);
        });
        this.cdr.markForCheck();

        if(this.sequencingSettings[this.service.PROPORTION_CONTROL_SELECTED_ATTRIBUTE_ID]) {
            this.proportionAttributeId = this.sequencingSettings[this.service.PROPORTION_CONTROL_SELECTED_ATTRIBUTE_ID][0].value as number;
        }

        this.loadAttributeDataFromRuleService();
        this.dirty = false;
        this.cdr.markForCheck();
      }, error => this.errorHandler.handleHttpError(error));

  }

    private translate(a: LaneProportion) {
        return this.translatePipe.transform("item.attributeValues." + a.target);
    }

    discardProportionSettingChanges(proportionSetting: ProportionSettings) {
    proportionSetting.discard();
    this.proportionSettings = [...this.proportionSettings];
    this.cdr.markForCheck();
    this.check100Percent();
  }

  resetProportionCounts(attributeValue: string) {
    this.dialogService.submitDialog('pages.settings.sequencing.proportionControl.reset-counter-hint', 'danger', {attributeValue: attributeValue})
      .subscribe(result => {
        if (result) {
          this.snackBar.showInfo('pages.settings.sequencing.proportionControl.counter-reset');
          this.proportionService.resetProportionCounts(attributeValue);
            timer(500).subscribe(x => this.reloadCounts());
        }
      });

  }

  check100Percent() {

    this.totalPlannedInvalid = false;

    this.proportionSettings
      .forEach(setting => {
        this.currentTotalPlanned[setting.attributeValue] = setting.proportions.reduce((sum, setting) => sum + setting.proportion, 0);
        if (this.currentTotalPlanned[setting.attributeValue] !== 100) {
          this.totalPlannedInvalid = true;
        }
      });
    this.cdr.markForCheck();
  }

  changeProportionControlResetEnabled($event: boolean) {
    this.dirty = true;
    this.sequencingSettings[this.service.PROPORTION_CONTROL_RESET_ENABLED][0].value = $event ? 'true' : 'false';
    this.proportionControlResetEnabled = $event;
  }

  showSetAttributeDialog() {
      this.dialogService.componentDialog(ChooseAttributeDialogComponent, {
          data: {mdsEntityAttributes: this.mdsEntityAttributes},
          maxWidth: '500px'
      })
          .afterClosed()
          .subscribe(({applyValue, selectedAttribute}) => {
                  if (applyValue) {
                      this.selectedAttribute = selectedAttribute;
                      this.proportionSettings = [];
                      this.proportionAttributeId = selectedAttribute.id;
                      if(this.sequencingSettings[this.service.PROPORTION_CONTROL_SELECTED_ATTRIBUTE_ID]) {
                          this.sequencingSettings[this.service.PROPORTION_CONTROL_SELECTED_ATTRIBUTE_ID][0].value = selectedAttribute.id;
                      } else {
                          this.sequencingSettings[this.service.PROPORTION_CONTROL_SELECTED_ATTRIBUTE_ID] =
                              [{settingKey: this.service.PROPORTION_CONTROL_SELECTED_ATTRIBUTE_ID,
                                  value: selectedAttribute.id} as SequencingSettings];
                      }

                      this.loadAttributeDataFromRuleService()
                      this.dirty = true;
                  }
                  this.cdr.markForCheck();
              }
          );
  }

  showTargetHistoryDialog( attributeValue: string, target: string) {
      this.dialogService.componentDialog(TargetHistoryDialogComponent, {
          data: { attributeValue: attributeValue,
                  attribute: this.selectedAttribute.entityTypeName + "." + this.selectedAttribute.attributeName,
                  target: target},
          width: '1000px'
      });
  }

  addAttribute(addAttributeValue: string) {
    // don't add if null, empty or already in the list
    if(!addAttributeValue || !addAttributeValue.trim()
        || this.proportionSettings.some((setting) =>
            setting.attributeValue === addAttributeValue))  {
        this.snackBar.showInfo('messages.empty-or-existing')
        return;
    }
    this.proportionSettings.push(new ProportionSettings(addAttributeValue, [
      {target: '280_LL', proportion: 25},
      {target: '380_LL', proportion: 25},
      {target: 'N50_A80', proportion: 25},
      {target: '480_LL', proportion: 25}]));


      this.addAttributeValue = " "; // why does this not work with "" ?
      this.cdr.markForCheck();
      this.dirty = true;
      this.check100Percent();
  }

  removeAttribute(index: number) {
    this.proportionSettings.splice(index, 1);
    this.dirty = true;
  }

}
