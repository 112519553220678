import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MfwItem, MfwItemParameterGroup, MfwItemSimpleParameter} from 'skw-ui-modules';
import {SkwApiService} from 'skw-ui-bootstrap';
import {SkwErrorHandlerService, SkwSelectModel, SkwSnackBarService} from 'skw-ui-components';
import {SkwTranslatableValue} from 'skw-ui-translation';

@Component({
  selector: 'app-manual-allowed-outlets-form',
  templateUrl: './manual-allowed-outlets-form.component.html',
  styleUrls: ['./manual-allowed-outlets-form.component.scss']
})
export class ManualAllowedOutletsFormComponent {

  selectedTarget: string;
  @Input() layoutId: string;
  configuredOutletIdentifiers: string[];
  @Output() select = new EventEmitter<any>();
  editing: boolean = false;
  // allowed outlets (1 for PVC 2-4 for LL, 5 for N50)
  allowedOutlets: {};
  showRequestBtn: boolean =  true;
  private _item: MfwItem;

  constructor(private api: SkwApiService,
              private snackBar: SkwSnackBarService,
              private errorHandler: SkwErrorHandlerService) {

    this.api.getBootstrap().subscribe(
      bootstrap => {
        const hideRequestBtnSettingEnabled = bootstrap.getParameter('hide-target-request-button') as boolean;
        if (hideRequestBtnSettingEnabled) {
          this.showRequestBtn = false;
        }
      });
  }

  @Input() set item(value: MfwItem) {
    this._item = value;
    this.api.get<string[]>(`/process/assigntarget/${this.layoutId}/outletIdentifiers`).subscribe(r => this.configuredOutletIdentifiers = r);
  }

  onEditAllowedOutlets() {
    let allowedOutlets = (this._item.parameters as MfwItemParameterGroup[])
      .find(p => p.name === 'ItemFlow').parameters.map(p => p as MfwItemSimpleParameter)
      .find(p => p.key === 'allowedOutlets');

    let outlets: string[] = [];
    if (allowedOutlets && allowedOutlets.value) {
      outlets = allowedOutlets.value
        .split('');
    }

    this.allowedOutlets = {};
    for (let i = 1; i < 7; i++) {
      if(this.configuredOutletIdentifiers.includes(i.toString())){
        let numStr = i.toString();
        this.allowedOutlets[numStr] = outlets.includes(numStr);
      }
    }
    this.editing = true;
  }

  onAbort() {
    this.editing = false;
  }

  getAllowedOutletsString(): string {
    let result = '';
    for (let key in this.allowedOutlets) {
      if (this.allowedOutlets[key] === true) {
        result = result.concat(key);
      }
    }
    return result;
  }

  onSetAllowedOutlets() {
    this.api.post(`/process/setallowedoutlets/${this.layoutId}`, undefined, {
      params: {
        itemId: this._item.id,
        value: this.getAllowedOutletsString()
      }
    }).subscribe(() => {
      this.snackBar.showInfo('item-modal.successfully-set');
      this.select.emit();
    }, error => this.errorHandler.handleHttpError(error));
  }

  onRequestAllowedOutlets() {
    this.api.post(`/process/requestallowedoutlets/${this.layoutId}`, undefined, {
      params: {
        itemId: this._item.id
      }
    }).subscribe(() => {
      this.snackBar.showInfo('item-modal.successfully-req');
      //this.select.emit();
    }, error => this.errorHandler.handleHttpError(error));
  }

}
