import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MfwItemModalComponent} from './mfw-item/mfw-item-modal/mfw-item-modal.component';
import {
  SkwAlertModule,
  SkwBadgeModule,
  SkwButtonModule,
  SkwClipboardModule,
  SkwCollapseModule,
  SkwDialogModule,
  SkwFormModule,
  SkwHintModule,
  SkwInputModule,
  SkwListModule,
  SkwLoadingModule,
  SkwTableModule,
  SkwUtilsModule
} from 'skw-ui-components';
import {SkwLanguageModule} from 'skw-ui-translation';
import {ManualTargetingFormComponent} from './mfw-item/mfw-item-modal/manual-targeting-form/manual-targeting-form.component';
import {MfwModuleModalComponent} from './mfw-module-modal/mfw-module-modal.component';
import {MfwRollerBeltModalComponent} from './mfw-roller-belt-modal/mfw-roller-belt-modal.component';
import {MfwAreaModalComponent} from './mfw-area-modal/mfw-area-modal.component';
import {ItemParameterComponent} from './mfw-item/mfw-item-modal/item-parameter/item-parameter.component';
import {CustomItemParameterDirective} from './mfw-item/mfw-item-modal/item-parameter/custom-item-parameter.directive';
import {MatTooltipModule} from '@angular/material';
import {SkwAuthorizationModule} from 'skw-ui-authentication';
import {ManualSubTargetFormComponent} from './mfw-item/mfw-item-modal/manual-subtarget-form/manual-sub-target-form.component';
import {DateStringPipe} from './date-string.pipe';
import {MfwTransferCarModalComponent} from './mfw-transfer-car-modal/mfw-transfer-car-modal.component';
import {MfwItemLocksInfoComponent} from './mfw-item/mfw-item-locks-info/mfw-item-locks-info.component';
import {SchedulerModule} from '../pages/scheduler/scheduler.module';
import {ManualAllowedOutletsFormComponent} from './mfw-item/mfw-item-modal/manual-allowed-outlets-form/manual-allowed-outlets-form.component';
import {AudiCommonModule} from '../audi-common/audi-common.module';
import {MfwItemLocksIssuerComponent} from './mfw-item/mfw-item-locks-issuer/mfw-item-locks-issuer.component';
import {RouterModule} from '@angular/router';
import {MfwItemParamIssuerComponent} from './mfw-item/mfw-item-param-issuer/mfw-item-param-issuer.component';
import {MfwSpotModalComponent} from './mfw-spot-modal/mfw-spot-modal.component';
import {MatTabsModule} from '@angular/material/tabs';
import {SequenceInfoComponent} from './sequence-info/sequence-info.component';
import {SequenceInfoItemComponent} from './sequence-info/sequence-info-item/sequence-info-item.component';
import {SequenceInfoItemDetailsComponent} from './sequence-info/sequence-info-item-details/sequence-info-item-details.component';
import { MfwTransitionModalComponent } from './mfw-transition-modal/mfw-transition-modal.component';
import {UnlockItemFormComponent} from "./mfw-item/mfw-item-modal/unlock-item-form/unlock-item-form.component";
import {MfwItemModule} from "skw-ui-modules";


const COMPONENTS = [
  ManualAllowedOutletsFormComponent,
  MfwItemModalComponent,
  ManualTargetingFormComponent,
  ManualSubTargetFormComponent,
  UnlockItemFormComponent,
  MfwModuleModalComponent,
  MfwRollerBeltModalComponent,
  MfwSpotModalComponent,
  MfwAreaModalComponent,
  ItemParameterComponent,
  CustomItemParameterDirective
];

@NgModule({
  declarations: [...COMPONENTS, DateStringPipe, MfwTransferCarModalComponent, MfwItemLocksInfoComponent, MfwItemLocksIssuerComponent, MfwItemParamIssuerComponent, SequenceInfoComponent, SequenceInfoItemComponent, SequenceInfoItemDetailsComponent, MfwTransitionModalComponent],
    imports: [
        CommonModule,
        SkwListModule,
        SkwLanguageModule,
        SkwInputModule,
        SkwButtonModule,
        SkwAlertModule,
        SkwFormModule,
        MatTooltipModule,
        SkwLoadingModule,
        SkwAuthorizationModule,
        SkwHintModule,
        SkwDialogModule,
        SkwClipboardModule,
        SchedulerModule,
        AudiCommonModule,
        RouterModule,
        SkwTableModule,
        SkwBadgeModule,
        MatTabsModule,
        SkwCollapseModule,
        SkwUtilsModule,
        MfwItemModule
    ],
  exports: [...COMPONENTS, DateStringPipe, MfwItemLocksInfoComponent, MfwItemParamIssuerComponent],
  entryComponents: [MfwItemModalComponent, MfwModuleModalComponent, UnlockItemFormComponent]
})
export class MfwComponentsModule {
}
