import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {indicate, SkwErrorHandlerService, SkwFilterElement, SkwSelectModel} from "skw-ui-components";
import {MdsEntityAttribute, MdsFieldDefinition} from "../../../rules/rule-definitions-model";
import {SearchService} from "../../../carbodysearch/search.service";
import {SearchParameter} from "../../../carbodysearch/search-parameter";
import {BehaviorSubject} from "rxjs";


@Component({
  selector: 'target-history-dialog',
  templateUrl: './target-history-dialog.component.html',
  styleUrls: ['./target-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TargetHistoryDialogComponent {

  mdsEntityAttributes: SkwSelectModel<MdsEntityAttribute>[];
  selectedAttribute: MdsEntityAttribute;
  $loading = new BehaviorSubject(true);
  items = [];
  query: string;
  aggregations;
  result;
  scrollId: string;
  numResults: number;

  constructor(private dialogRef: MatDialogRef<TargetHistoryDialogComponent>,
              private searchService: SearchService,
              private errorHandlerService: SkwErrorHandlerService,
              private cdr: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) public data: { attributeValue: string, attribute: string, target: string }) {
    this.doSearch();
  }

  private search() {
    const param = {
      query: "",
      pcsTermsFilters: [{name: this.data.attribute, terms: [this.data.attributeValue]},
        {name: "itemFlow.target", terms: [this.data.target]}]
    } as SearchParameter;

    return this.searchService.targetSearch(param);
  }

  doSearch() {
    this.search()
        .pipe(indicate(this.$loading))
        .subscribe(resp => this.showResults(resp),
            error => this.errorHandlerService.handleHttpError(error));
  }

  showResults(results: any) {
    this.result = results;

    const items = [];
    for (const hit of results.hits) {
      items.push(hit.source);
    }
    this.items = items;
    this.aggregations = results.aggregations;
    this.scrollId = results.scrollId;
    this.numResults = results.total;
    this.cdr.markForCheck();
  }

  loadMoreResults() {
    this.searchService
      .scroll(this.scrollId)
      .subscribe(resp => this.addToResults(resp),
        error => this.errorHandlerService.handleHttpError(error));
  }

  private addToResults(additionalResults: any) {
    // just add the new items to our items array
    const additionalItems = [];
    for (const hit of additionalResults.hits) {
      additionalItems.push(hit.source);
    }
    this.items = [...this.items, ...additionalItems];

    // and set the new scrollId if you need to continue from there
    this.scrollId = additionalResults.scrollId;
  }


  close() {
    this.dialogRef.close();
  }

  setAttributeReference($event: MdsEntityAttribute) {
    this.selectedAttribute = $event ? $event : undefined;
  }

}
